/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getSpecial1Subscription, getUserProfile } from "../../redux/actions";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import moment from "moment/moment";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import Loader from "../../components/Loader";
const validationSchema = yup.object().shape({
  textarea: yup.string().required("Please enter something!"),
});

import { isEmpty } from "lodash";
import { useFormik } from "formik";
// Components
import FormikErrorComponent from "../../components/AuthComponents/FormikErrorComponent";
import ErrorHandleComponent from "../../components/ErrorHandleComponent";
// Constants
import {
  initialValuesContact,
  contactSchema,
} from "../../constants/Authentication";
// Actions and Slices
import { reset } from "../../redux/slices/authSlice";
// Image
import useAutoCompleteFocus from "../../components/useAutoCompleteFocus";
const FormsSpecial1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shouldrender = useRef(true);
  const { userData, updateUserData } = useSelector((state) => state.profile);
  useEffect(() => {
    if (shouldrender.current) {
      shouldrender.current = false;
      dispatch(getUserProfile());
    }
  }, [updateUserData]);

  const { loading, contactEmailErrorMessage, contactEmailSuccessMessage } =
    useSelector((state) => state.auth);
  const handleOnSearch = (string) => {
    setSubject(string);
    formik.setFieldValue("subject", string);
  };
  const handleOnSelect = (item) => {
    setSubject(item.name);
    formik.setFieldValue("subject", item.name);
  };
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const [subject, setSubject] = useState("");
  const [contactSuccess, setContactSuccess] = useState(false);
  const [selectHasValue, setSelectHasValue] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (contactEmailSuccessMessage) {
      setContactSuccess(true);
    }
  }, [contactEmailSuccessMessage]);

  const onSubmit = (values) => {
    setSubject("");
    if (userData.username && userData.id && userData.email) {
      values.subscription_type = "Special1";
      values.userId = userData.id;
      values.email = userData.email;
      values.username = userData.username;
      (values.duration = "3 months"),
        dispatch(getSpecial1Subscription(values))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
          });
      formik.resetForm();
    } else {
      toast.error("You need to login in to send this request!");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesContact,
    validationSchema: contactSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.setFieldValue(
      "name",
      userData?.first_name + " " + userData?.last_name
    );
    formik.setFieldValue("username", userData?.username);
    formik.setFieldValue("email", userData?.email);
    setSubject("Request Special1 access for 3 months");
    setSelectHasValue(true);
    formik.setFieldValue("subject", "Request Special1 access for 3 months");
  }, [userData?.username, userData?.email]);

  const parentRef = useRef(null);
  const { isFocused } = useAutoCompleteFocus({
    parentRef,
    onBlurred: () => {
      const inputValue = parentRef.current.querySelector("input").value;
      if (inputValue) {
        setSelectHasValue(true);
      } else {
        setSelectHasValue(false);
      }
    },
  });

  const handleFilterDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/,/g, "");
    return formattedDate;
  };

  return (
    <>
      <div className="inner_banner_section">
        <div class="container">
          <h1>Special1 access request</h1>
        </div>
      </div>
      <div className="centered-content text-center">
        {/* =========================== */}
        {["Premium", "premium"].includes(
          userData?.user_data?.subscription_type
        ) ? (
          <div className="my-3 pt-4 pb-4 px-3 contact_page">
            <div className=" max-w-800 mx-auto text-start">
              <p className="mb-3 mt-0 mb-3">
                You already have <span className="text-orange">Premium</span>{" "}
                access. You do not need Special1 access. Should you have any
                questions or concerns, please{" "}
                <Link to="/contact" className="text-blue">
                  contact us
                </Link>
                .
              </p>
            </div>
          </div>
        ) : null}
        {/* ================================ */}
        {["Special1", "special1"].includes(
          userData?.user_data?.subscription_type
        ) ? (
          <div className="my-3 pt-4 pb-4 px-3 contact_page">
            <div className=" max-w-800 mx-auto text-start">
              <p className="mb-3 mt-0 mb-3">
                You already have <span className="text-orange">Special1</span>{" "}
                access (it expires{" "}
                <span className="text-orange">
                  {userData?.user_data?.expiry_date &&
                  !["basic", "Basic"].includes(
                    userData?.user_data?.subscription_type
                  )
                    ? handleFilterDate(userData?.user_data?.expiry_date)
                    : !["basic", "Basic"].includes(
                        userData?.user_data?.subscription_type
                      )
                    ? "--"
                    : null}
                </span>
                ). Once it expires you can request again. Should you have any
                questions or concerns, please{" "}
                <Link to="/contact" className="text-blue">
                  contact us
                </Link>
                .
              </p>
            </div>
          </div>
        ) : null}
        {/* ================================ */}
        {["pending", "Pending"].includes(userData?.user_data?.status) &&
        ["basic", "Basic"].includes(userData?.user_data?.subscription_type) ? (
          <div className="my-3 pt-4 pb-4 px-3 contact_page">
            <div className=" max-w-800 mx-auto text-start">
              <p className="mb-3 mt-0 mb-3">
                Your <span className="text-orange">Special1</span> request is
                pending, we will get back to you ASAP. Should you have any
                questions or concerns, please{" "}
                <Link to="/contact" className="text-blue">
                  contact us
                </Link>
                .
              </p>
            </div>
          </div>
        ) : null}
        {/* =============FORM BELOW============== */}
        {userData.username &&
        userData.id &&
        userData.email &&
        ["basic", "Basic"].includes(userData?.user_data?.subscription_type) &&
        !["Pending", "pending"].includes(userData?.user_data?.status) ? (
          <div className="contact_page text-start">
            <div className="contact_page_container">
              <section className="contact_form_section">
                <Container>
                  <div className="contact_form_banner_section">
                    <div className="contact_banner">
                      {/* <h2>Email</h2> */}
                      <p className="max-w-400">
                        <strong>
                          Should I pay for Premium or apply for Special1?
                        </strong>
                      </p>
                      {/* <img src={conatactImg} /> */}
                      <p>
                        If you can afford it, please consider paying. Your
                        contribution helps us sustain and grow, and we only
                        charge a nominal amount.
                      </p>
                      <p>
                        However, if paying for Premium would mean cutting down
                        on life necessities, please apply for Special1 instead.
                        We do NOT turn anyone away simply because they cannot
                        pay. Just submit the form and share your story with us.
                      </p>
                    </div>
                    <div className="contact_form_wrapper">
                      <h2>Request form</h2>
                      <form
                        className="contact_form"
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="contact_form_group">
                          <div className="contact_form_group_item blur-readonly">
                            <input
                              id="name"
                              placeholder="Your name"
                              className="input_field user-field"
                              type="text"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              readOnly
                              onMouseDown={(e) => e.preventDefault()}
                            />
                            <label className="input_label">Your name</label>
                            <FormikErrorComponent
                              errorName={formik.errors.name}
                              touched={formik.touched.name}
                            />
                          </div>
                          <div className="contact_form_group_item blur-readonly">
                            <input
                              id="email"
                              type="text"
                              className="input_field email-field"
                              placeholder="Username"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              readOnly
                              onMouseDown={(e) => e.preventDefault()}
                            />
                            <label className="input_label">Username</label>
                            <FormikErrorComponent
                              errorName={formik.errors.username}
                              touched={formik.touched.username}
                            />
                          </div>
                        </div>
                        <div className="contact_form_select">
                          <div
                            className={`${
                              isFocused || selectHasValue
                                ? "contact_form_select_input contact_form_select_focus blur-readonly"
                                : "contact_form_select_input blur-readonly"
                            }`}
                          >
                            <label>Subject</label>
                            <div
                              ref={parentRef}
                              style={{ pointerEvents: "none" }}
                            >
                              <ReactSearchAutocomplete
                                onSearch={handleOnSearch}
                                onSelect={handleOnSelect}
                                formatResult={formatResult}
                                showIcon={false}
                                showClear={false}
                                inputSearchString={subject}
                                className="hello blur-readonly"
                              />
                            </div>
                          </div>
                          <FormikErrorComponent
                            errorName={formik.errors.subject}
                            touched={formik.touched.subject}
                          />
                        </div>
                        <div className="contact_form_group_item">
                          <textarea
                            id="message"
                            className="message-field"
                            placeholder="Your Message"
                            rows="4"
                            cols="50"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                          ></textarea>
                          <label className="input_label">Your story</label>
                          <FormikErrorComponent
                            errorName={formik.errors.message}
                            touched={formik.touched.message}
                          />
                        </div>
                        {!isEmpty(contactEmailErrorMessage) && (
                          <ErrorHandleComponent
                            errorMessage={contactEmailErrorMessage}
                          />
                        )}
                        <div className="primary-btn">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <Loader /> : "Send Request"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Container>
              </section>
            </div>
          </div>
        ) : null}

        {/* =============Free Subscription User Start ============ */}
        {userData.username &&
        userData.id &&
        userData.email &&
        ["free", "Free"].includes(userData?.subscription_type) &&
        !userData?.user_data?.subscription_type ? (
          <div className="contact_page text-start">
            <div className="contact_page_container">
              <section className="contact_form_section">
                <Container>
                  <div className="contact_form_banner_section">
                    <div className="contact_banner">
                      {/* <h2>Email</h2> */}
                      <p className="max-w-400">
                        <strong>
                          Should I pay for Premium or apply for Special1?
                        </strong>
                      </p>
                      {/* <img src={conatactImg} /> */}
                      <p>
                        If you can afford it, please consider paying. Your
                        contribution helps us sustain and grow, and we only
                        charge a nominal amount.
                      </p>
                      <p>
                        However, if paying for Premium would mean cutting down
                        on life necessities, please apply for Special1 instead.
                        We do NOT turn anyone away simply because they cannot
                        pay. Just submit the form and share your story with us.
                      </p>
                    </div>
                    <div className="contact_form_wrapper">
                      <h2>Request form</h2>
                      <form
                        className="contact_form"
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="contact_form_group">
                          <div className="contact_form_group_item blur-readonly">
                            <input
                              id="name"
                              placeholder="Your name"
                              className="input_field user-field"
                              type="text"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              readOnly
                              onMouseDown={(e) => e.preventDefault()}
                            />
                            <label className="input_label">Your name</label>
                            <FormikErrorComponent
                              errorName={formik.errors.name}
                              touched={formik.touched.name}
                            />
                          </div>
                          <div className="contact_form_group_item blur-readonly">
                            <input
                              id="email"
                              type="text"
                              className="input_field email-field"
                              placeholder="Username"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              readOnly
                              onMouseDown={(e) => e.preventDefault()}
                            />
                            <label className="input_label">Username</label>
                            <FormikErrorComponent
                              errorName={formik.errors.username}
                              touched={formik.touched.username}
                            />
                          </div>
                        </div>
                        <div className="contact_form_select">
                          <div
                            className={`${
                              isFocused || selectHasValue
                                ? "contact_form_select_input contact_form_select_focus blur-readonly"
                                : "contact_form_select_input blur-readonly"
                            }`}
                          >
                            <label>Subject</label>
                            <div
                              ref={parentRef}
                              style={{ pointerEvents: "none" }}
                            >
                              <ReactSearchAutocomplete
                                onSearch={handleOnSearch}
                                onSelect={handleOnSelect}
                                formatResult={formatResult}
                                showIcon={false}
                                showClear={false}
                                inputSearchString={subject}
                                className="hello blur-readonly"
                              />
                            </div>
                          </div>
                          <FormikErrorComponent
                            errorName={formik.errors.subject}
                            touched={formik.touched.subject}
                          />
                        </div>
                        <div className="contact_form_group_item">
                          <textarea
                            id="message"
                            className="message-field"
                            placeholder="Your Message"
                            rows="4"
                            cols="50"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                          ></textarea>
                          <label className="input_label">Your story</label>
                          <FormikErrorComponent
                            errorName={formik.errors.message}
                            touched={formik.touched.message}
                          />
                        </div>
                        {!isEmpty(contactEmailErrorMessage) && (
                          <ErrorHandleComponent
                            errorMessage={contactEmailErrorMessage}
                          />
                        )}
                        <div className="primary-btn">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <Loader /> : "Send Request"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Container>
              </section>
            </div>
          </div>
        ) : null}
        {/* =========== Free Subscription User End */}
        {!userData.username && !userData.id && !userData.email ? (
          <div className="button-group my-3 pt-4 pb-4 px-3 contact_page">
            <div className=" max-w-600 mx-auto">
              <p className="mb-3 mt-0 mb-3">
                <span className="">
                  To Request <span className="text-orange">Special1</span>{" "}
                  access log in to your account.
                </span>
              </p>
              <button
                type="submit"
                className="form-btn fw-normal mt-3 btn btn-primary cs-btn-lg"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </div>
          </div>
        ) : null}
        {/* =============FORM END HERE============== */}
      </div>
    </>
  );
};

export default FormsSpecial1;
