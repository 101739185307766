export const footerContents = [
	{ to: '/about#mission-section', text: 'Our mission' },
	{ to: '/terms-of-use', text: 'Terms of use' },
	{ to: '/privacy-policy', text: 'Privacy policy' },
	{ to: '/about', text: 'About us' },
	{ to: '/contact', text: 'Contact us' },
	{ to: '/practice#question-section22', text: 'Practice questions' },
	{ to: '/resources', text: 'Resources (FREE)' },
	{ to: '/intela', text: 'InteLa[TM]' },
	{ to: '/pricing', text: 'Pricing' },
	{ to: '/pricing#discount-section', text: 'Discount for early users' },
	{ to: '/forms/special1', text: 'Request Special1 access' },
	{ to: '/#testimonials-section', text: 'Testimonials' },
	{ to: '/faq', text: 'FAQ' }
];
