import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { Container, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import moment from 'moment/moment';
import Loader from '../../../components/Loader';
import TableHeadComponent from '../../../components/PracticeSetComponents/TableHeadComponent';
import TableContentsComponent from '../../../components/PracticeSetComponents/TableContentsComponent';
import {
	paymentSessionCreate,
	subscriptionList,
	currentPlan,
	stopAutoRecurring
} from '../../../redux/actions';
import ErrorHandleComponent from '../../../components/ErrorHandleComponent';
import {
	discounts,
	tableContents,
	tableHeadContents
} from '../../../constants/PricingPageData/index';
import InfoCircleIcon from '../../../assets/images/icons/info-circle-icon.svg';
import './styles.scss';
import CustomModal from '../../../components/CustomModal';

const PricingPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const shouldRender = useRef(true);
	const [autoRenewalModal, setAutoRenewalModal] = useState(false);
	const {
		loading,
		paymentSessionUrl,
		currentplaneStatus,
		paymentSessionError,
		stopAutoRecurringSuccess,
		stopAutoRecurringError
	} = useSelector((state) => state.payment);
	const { isLoggedIn, loading: authLoading } = useSelector((state) => state.auth);

	useEffect(() => {
		if (shouldRender.current && isLoggedIn) {
			shouldRender.current = false;
			dispatch(currentPlan());
		}
		if (currentplaneStatus?.status_code === 400 && isLoggedIn) {
			dispatch(subscriptionList());
			dispatch(paymentSessionCreate());
		}
	}, [currentplaneStatus, isLoggedIn]);

	useEffect(() => {
		if (stopAutoRecurringSuccess && stopAutoRecurringSuccess.status_code === 200) {
			toast.success(stopAutoRecurringSuccess.message);
			dispatch(currentPlan());
		} else if (stopAutoRecurringError) {
			toast.error(stopAutoRecurringError);
		}
	}, [stopAutoRecurringSuccess, stopAutoRecurringError]);

	if (loading || authLoading) {
		return (
			<div className='loader_section'>
				<Loader />
			</div>
		);
	}

	if (currentplaneStatus.message === 'Network Error') {
		return (
			<ErrorHandleComponent errorMessage={currentplaneStatus.message} />
		);
	}

	if (!isEmpty(paymentSessionError)) {
		return (
			<ErrorHandleComponent errorMessage={paymentSessionError} />
		);
	}

	const handleStopAutoRecurringModal = () => {
		setAutoRenewalModal(!autoRenewalModal);
	};

	const handleStopAutoRecurring = () => {
		dispatch(stopAutoRecurring());
		setAutoRenewalModal(false);
	};

	const autoRenewaFooter = [
		{
			label: 'Cancel',
			variant: 'danger',
			onClick: () => setAutoRenewalModal(false)
		},
		{
			label: 'OK',
			variant: 'primary',
			onClick: handleStopAutoRecurring
		}
	];

	return (
		<div className='pricing_page'>
			<section className='inner_banner_section'>
				<Container>
					<h1>Pricing</h1>
				</Container>
			</section>
			<div className='pricing_content'>
				<Container>
					<p>
						We offer a subscription model where pricing is based on
						access to different products and services.
						We will revise our pricing model as we evolve.
						Our goal is to provide the best learning experience at a sustainable
						price. <mark>Discounts are available if you sign up early; scroll
						down for details</mark>.
					</p>
					<div className='pricing_table'>
						<div className='inner_table'>
							<table>
								<thead>
									<tr>
										{tableHeadContents.map((e, i) => {
											return (
												<TableHeadComponent key={i} data={e} />
											);
										})}
									</tr>
								</thead>
								<tbody>
									{tableContents.map((e, i) => {
										return (
											<TableContentsComponent key={i} data={e} />
										);
									})}
									{
										!isLoggedIn && (
											<tr>
												<td></td>
												<td></td>
												<td>
													<Button onClick={() => navigate('/signup')}>
														Select this
														<OverlayTrigger
															placement='bottom'
															overlay={
																<Tooltip>
																	It will take you to sign-up
																</Tooltip>
															}
														>
															<ReactSVG src={InfoCircleIcon} />
														</OverlayTrigger>
													</Button>
												</td>
												<td>
													<Button onClick={() => navigate('/signup')}>
														Select this
														<OverlayTrigger
															placement='bottom'
															overlay={
																<Tooltip>
																	It will take you to sign-up.
																	After signing-up you can upgrade
																	to premium.
																</Tooltip>
															}
														>
															<ReactSVG src={InfoCircleIcon} />
														</OverlayTrigger>
													</Button>
												</td>
											</tr>
										)
									}
									{
										isLoggedIn && currentplaneStatus.error && (
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td>

													<Button href={paymentSessionUrl} target={'_blank'}>
														Upgrade to Premium
													</Button>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
							<p>
								*1 <strong>On-demand Practice</strong> is coming soon (expected early June 2024).
								This will be available for most, if not all, question sets.
							</p>
							<p>
								*2 <strong>Adaptive Practice</strong> is coming soon (expected early June 2024).
								This will be available for some selected question sets.
							</p>
							<p>
								*3 Currently, both Basic and Premium accounts have access to a simple dashboard
								for tracking weekly progress.
								An enhanced dashboard is under development for Premium users
								(expected August 2024).
								This upgraded dashboard will offer personalization and actionable analytics.
							</p>
						</div>
						{
							isLoggedIn && !currentplaneStatus.error && (
								<div
									className={`pricing_page_expires 
									${currentplaneStatus?.data?.auto_recurring ? '' : 'text-right'}`}>
									<p>Your current subscription expires on:{' '}
										{moment(currentplaneStatus?.data?.plan_end_date,
											'ddd, DD MMM YYYY HH:mm:ss z').format('DD MMM YYYY')}</p>
									{
										currentplaneStatus?.data?.auto_recurring
											? <div>
												<p>Your subscription will renew automatically 30 days before expiry.</p>
												<p> You can
													<Button onClick={() => handleStopAutoRecurringModal()}>
														opt out of auto renewal
													</Button> anytime.
												</p>
											</div>
											:	 <div>
												<p>You can renew for another year on or after:&nbsp;
													<span>
														{moment(currentplaneStatus?.data?.plan_end_date,
															'ddd, DD MMM YYYY HH:mm:ss z')
															.subtract(30, 'days').format('DD MMM YYYY')}
													</span>
												</p>
												<p>While renewing you can opt for <strong>auto renewal</strong>.</p>
											</div>
									}
								</div>
							)
						}
						<div className='pricing-info' id="discount-section">
							<h3>^ Your payment is secure ...</h3>
							<p>
								We (boo9 inc.) do not record or store your credit card or other payment information.
								Your payment is processed securely through{' '}
								<a
									href='https://stripe.com/'
									target='_blank'
									rel='noreferrer'
								>
									Stripe
								</a>.
							</p>
							<h3>* Lifetime discount for the first 1M <i>Premium</i> users:</h3>
							<p>
								<b>Lifetime discount:</b>{' '}
								We are committed to bringing you world-class content over time, which requires
								a lot of investment. So to encourage early sign-ups we are offering <mark>lifetime
								discount</mark> for the first 1M <i>premium</i> users. <u>Discount will be applied
								automatically during checkout</u> based on your <i>premium</i> user number. Larger
								discount for earlier <i>premium</i> users, calculated based on the following table.
							</p>
							<div className='discount_table'>
								<table>
									<thead>
										<tr>
											<th>Premium user no.</th>
											<th>Discount code</th>
											<th>Discount</th>
										</tr>
									</thead>
									<tbody>
										{discounts.map((e, i) => {
											return (
												<tr key={i}>
													<td>{e.user_no}</td>
													<td>{e.discount_code}</td>
													<td>{e.discount}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							<div className='text'>
								Terms:
								<ol>
									<li>
										<u>Lifetime meaning</u> <strong>if there is no break in the subscription, your %
										discount
										will be valid for a lifetime.</strong> You will continue to receive the same
										% discount even if we increase the subscription price later. This discount
										will be void if there is a break in the subscription.
									</li>
									<li>
										<strong>We reserve the right to end this offer anytime without notice</strong>,
										even before we reach 1M premium users.
									</li>
								</ol>
							</div>
							<div className='text'>
								<mark>Facing financial hardships?</mark>
								If you are facing financial hardships and cannot afford to pay,
								please tell us your <Link to='/forms/special1'>story</Link>.
								We will provide a special access for FREE for a few months (which you can renew).
							</div>
							<p>
								If you have any questions,
								please feel free to <Link to='/contact'>reach out to us</Link>.
							</p>
						</div>
					</div>
				</Container>
			</div>
			<CustomModal
				title='Opt-Out of Auto-Renewal'
				content={<p>Are You Sure You Want to Opt-Out of Auto-Renewal?</p>}
				footerButtons={autoRenewaFooter}
				show={autoRenewalModal}
				handleClose={handleStopAutoRecurringModal}
			/>
		</div>
	);
};

export default PricingPage;
